import React from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableRow, Checkbox, TableSortLabel } from '@material-ui/core'

import StyledTableCell from './TableCell'

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headRows } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
  
    return (        
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                <StyledTableCell
                    key={row.field}
                    align="center"
                    padding={row.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === row.field ? order : false}                    
                    >
                    <TableSortLabel
                        active={orderBy === row.field}
                        direction={order}
                        onClick={createSortHandler(row.field)}
                        >
                        {row.label}
                    </TableSortLabel>
                </StyledTableCell>
                ))}
                <StyledTableCell padding="checkbox">
                    <Checkbox
                        style={{ color: 'white' }}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'Select all' }}
                    />
                </StyledTableCell>
            </TableRow>
        </TableHead>
    )
}
  
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    headRows: PropTypes.array,
};

export default EnhancedTableHead