import React, { Fragment, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { makeStyles, } from '@material-ui/core/styles'
import TitleAndBreadcrumbs from '../core/TitleAndBreadcrumbs'
import ExpansionPanel from '../core/ExpansionPanel'
import Table from '../core/tableCareer'
import { API_URL } from '../utils/Constant'
import SearchIcon from '@material-ui/icons/Search'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles(theme => ({ 
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        marginRight: theme.spacing(5),
        minWidth: 200,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default () => {
    const baseUrl = API_URL.CAREER
    const classes = useStyles()
    const [searchUrl, setSearchUrl] = useState(baseUrl)
    const [filter, setFilter] = useState({ position: '' })

    const keys = [ "id" ]
    const columns = [
        { label: 'Position', field: 'position', max: '25' },
        { label: 'Employment Type', field: 'employment_type', max: '25' },
        { label: 'Expire Date', field: 'expire_date'},
    ]    

    const handleApply = () => {
        let position = filter.position || ''
        if(position !== '') {
            setSearchUrl(baseUrl + `?position=${position}`)
        }
        console.log(searchUrl)
    }

    const handleReset = () => {
        setFilter({ position: '' })
        setSearchUrl(baseUrl)
    }

    const handleChangeFilter = position => event => {
        setFilter({ ...filter, [position]: event.target.value });
    }

    return (
        <Fragment>
            <TitleAndBreadcrumbs title="Career" breads={[ "Career" ]} />
            <ExpansionPanel title="Filter" defaultExpanded>
                <Grid container className={classes.filterContent} justify="flex-start" alignItems="center">
                    <Grid item>
                        <div className={classes.formControl}>
                            <TextField 
                                label="Position"
                                value={filter.position}
                                onChange={handleChangeFilter('position')}
                                autoFocus
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </Grid>                    
                    <Grid item xs={12}>
                        <Grid container justify="flex-end" spacing={2}>
                            <Grid item>
                                <Button onClick={handleReset} variant="contained" color="default" size="medium" >
                                    <CancelIcon className={classes.leftIcon} />
                                    Reset
                                </Button> 
                            </Grid>
                            <Grid item>
                                <Button onClick={handleApply} variant="contained" color="primary" size="medium" >
                                    <SearchIcon className={classes.leftIcon} />
                                    Apply
                                </Button> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ExpansionPanel>
            <Table columns={columns} columnKeys={keys} url={searchUrl} />
        </Fragment>
    )
}