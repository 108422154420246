import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({ 
    filter: {
        marginTop: theme.spacing(1)
    },
    filterContent: {

    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
}))

const ExpansionPanel = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      borderTop: '2px solid #ffb763',
      boxShadow: 'none',      
      '&:before': {
        display: 'none',
      },
    },
    expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      minHeight: 20,
      '&$expanded': {
        minHeight: 20,
      },
    },
    content: {
      '&$expanded': {
        margin: '10px 0',
      },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
    },
}))(MuiExpansionPanelDetails);

export default ({ children, title, ...rest }) => {
  const classes = useStyles()
  return (
    <ExpansionPanel className={classes.filter} {...rest}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography className={classes.heading}>{ title }</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        { children }
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}