import React, { Fragment } from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Icon } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import MenuList from './MenuList'

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 0,
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
    nested: {
        paddingLeft: theme.spacing(7),
    },
    icon: {
        color: theme.palette.common.white
    },
}));

export default ({ path }) => {
    const classes = useStyles()
    const pathList = MenuList
    const [open, setOpen] = React.useState(() => {
        return pathList.map((one) => {
            if(one.child.length > 0) {
                return !!one.child.find(f => f.path === path)
            } else {
                return one.path === path
            }
        })
    });

    function handleClick(id) {
        setOpen({ [id] : !open[id] })
    }

    function isSelected(idParent, idChild) {
        let pathObj = pathList.find(pl => pl.id === idParent)
        if(idChild) {
            pathObj = pathObj.child.find(cl => {
                return cl.id === idChild
            })
        } else {
            if(pathObj.child.length > 0) {
                let clObj = pathObj.child.find(cl => {
                    return cl.path === path
                })
                return !!clObj
            }
        }
        return path === pathObj.path                
    }

    return <div style={{ overflow: 'auto' }}>
        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
            { 
                pathList.map((one, index) => { return (
                    one.child.length === 0 ? 
                    <ListItem button component={Link} to={one.path} selected={isSelected(`${one.id}`)} key={one.id}>
                        <ListItemIcon>
                            <Icon component={one.icon} className={classes.icon} />                            
                        </ListItemIcon>
                        <ListItemText primary={one.name}/>
                    </ListItem>
                    :
                    <Fragment key={one.id}>
                        <ListItem button onClick={() => handleClick(index)} selected={isSelected(`${one.id}`)}>
                            <ListItemIcon>
                                <Icon component={one.icon} className={classes.icon} />
                            </ListItemIcon>
                            <ListItemText primary={one.name} />
                            {open[index] ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open[index]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                            { one.child.map((inOne) => { return ( 
                                <ListItem button 
                                    className={classes.nested} 
                                    component={Link} 
                                    to={inOne.path} 
                                    selected={isSelected(`${one.id}`, `${inOne.id}`)}
                                    key={inOne.id}>
                                    <ListItemText primary={inOne.name} />
                                </ListItem>
                                )
                            })}  
                            </List>
                        </Collapse>
                    </Fragment>
                    )
                })
            }
        </List>
    </div>
}