import React, { Fragment, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import Select from '../core/Select'
import { makeStyles, } from '@material-ui/core/styles'
import TitleAndBreadcrumbs from '../core/TitleAndBreadcrumbs'
import ExpansionPanel from '../core/ExpansionPanel'
import Table from '../core/table'
import { API_URL } from '../utils/Constant'
import SearchIcon from '@material-ui/icons/Search'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles(theme => ({ 
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        marginRight: theme.spacing(5),
        minWidth: 200,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default () => {
    const baseUrl = API_URL.INSTRUCTOR
    const classes = useStyles()
    const [searchUrl, setSearchUrl] = useState(baseUrl)
    const [filter, setFilter] = useState({ name: '', title: '', is_active: '' })
    const cbxIsActive = [
        {
            value: "0",
            label: "Inactive"
        },
        {
            value: "1",
            label: "Active"
        }
    ] 

    const keys = [ "id" ]
    const columns = [
        { label: 'Name', field: 'instructor_name', max: '25' },
        { label: 'Title', field: 'instructor_title', max: '25' },
        { label: 'Photo', field: 'instructor_photo_path', type: 'image', 
            options: {
                width: 200,
                height: 200,
            } 
        },
        { label: 'Status', field: 'is_active', type: 'select', 
            options: cbxIsActive
        },
    ]

    const handleApply = () => {
        let url = baseUrl + "?"
        
        let name = filter.name || ''
        let title = filter.title || ''
        let is_active = filter.is_active.value || ''

        if(name !== '') {
            url += `name=${name}`
        }
        if(title !== '') {
            if(url !== '') {
                url += '&'
            }
            url += `title=${title}`
        }
        if(is_active !== '') {
            if(url !== '') {
                url += '&'
            }
            url += `is_active=${is_active}`
        }
        setSearchUrl(url)
    }

    const handleReset = () => {
        setFilter({ name: '', title: '', is_active: '' })
        setSearchUrl(baseUrl)
    }

    const handleChangeFilter = name => event => {
        if(event.target) {
            setFilter({ ...filter, [name]: event.target.value });
        } else {
            setFilter({ ...filter, [name]: event });
        }
    }

    return (
        <Fragment>
            <TitleAndBreadcrumbs title="Instructor" breads={[ "Content", "Instructor" ]} />
            <ExpansionPanel title="Filter" defaultExpanded>
                <Grid container className={classes.filterContent} justify="flex-start" alignItems="center">
                    <Grid item>
                        <div className={classes.formControl}>
                            <TextField 
                                label="Name"
                                value={filter.name}
                                onChange={handleChangeFilter('name')}
                                autoFocus
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.formControl}>
                            <TextField 
                                label="Title"
                                value={filter.title}
                                onChange={handleChangeFilter('title')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.formControl}>
                            <Select 
                                label="Status"
                                placeholder="Status"
                                inputId="is-show-react"
                                value={filter.is_active}
                                handler={handleChangeFilter('is_active')}
                                ismulti={false}
                                options={cbxIsActive}
                            />
                        </div>
                    </Grid>                    
                    <Grid item xs={12}>
                        <Grid container justify="flex-end" spacing={2}>
                            <Grid item>
                                <Button onClick={handleReset} variant="contained" color="default" size="medium" >
                                    <CancelIcon className={classes.leftIcon} />
                                    Reset
                                </Button> 
                            </Grid>
                            <Grid item>
                                <Button onClick={handleApply} variant="contained" color="primary" size="medium" >
                                    <SearchIcon className={classes.leftIcon} />
                                    Apply
                                </Button> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ExpansionPanel>
            <Table columns={columns} columnKeys={keys} url={searchUrl} />
        </Fragment>
    )
}