import React from 'react'
import { Drawer, Divider, CardMedia } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Logo from '../../assets/images/logo.png'

import Menu from './Menu'

const DrawerWidth = 220;

const useStyles = makeStyles(theme => ({
    // drawerPaper: {
    //     backgroundColor: theme.palette.primary.main,
    //     position: 'relative',
    //     whiteSpace: 'nowrap',
    //     width: DrawerWidth,
    //     transition: theme.transitions.create('width', {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.enteringScreen,
    //     }),
    // },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(0),
        },
    },
    toolbarIcon: {
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    icon: {
        color: theme.palette.common.white,        
    },
    logo: {
        // backgroundColor: theme.palette.secondary.main,
        height: 40,
        width: 165,
    },
    drawer: {
        width: DrawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: DrawerWidth,
        backgroundColor: theme.palette.primary.main,
    },
}))

const CustomDrawer = ({ open, handleDrawerClose, location }) => {
    const classes = useStyles()

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            open={open}
            color="primary"
            classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                // paper: classes.drawerPaper
            }}
            >
            <div className={classes.toolbarIcon}>
                <CardMedia image={Logo} className={classes.logo} />
                {/* <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon className={classes.icon}/>
                </IconButton> */}
            </div>
            <Divider />           
            <Menu path={location.pathname}/>
        </Drawer>
    )
}

export { CustomDrawer, DrawerWidth } 