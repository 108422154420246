import React, { useState, Fragment } from 'react'
import { Button, Grid } from '@material-ui/core'
import { makeStyles, } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import CancelIcon from '@material-ui/icons/Cancel'

import { API_URL } from '../utils/Constant'
import Select from '../core/Select'
import TitleAndBreadcrumbs from '../core/TitleAndBreadcrumbs'
import ExpansionPanel from '../core/ExpansionPanel'
import Table from '../core/table'

const useStyles = makeStyles(theme => ({ 
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        marginRight: theme.spacing(5),
        minWidth: 200,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default () => {
    const classes = useStyles()
    const [filter, setFilter] = useState({ cat: '', sub: ''})
    const [searchUrl, setSearchUrl] = useState(API_URL.SYSTEM)

    const keys = [ "category", "sub_category", "code" ]
    const columns = [
        { label: 'Category', field: 'category', },
        { label: 'Sub Category', field: 'sub_category' },
        { label: 'Code', field: 'code' },
        { label: 'Value', field: 'value', },
        { label: 'Remark', field: 'remark', },
    ]

    const handleApply = () => {
        let cat = filter.cat.value || ''
        let sub = filter.sub.value || ''
        if(cat !== '' || sub !== '') {
            setSearchUrl(API_URL.SYSTEM + `?cat=${cat}&sub=${sub}`)
        }
    }

    const handleReset = () => {
        setFilter({ cat: '', sub: '' })
        setSearchUrl(API_URL.SYSTEM)
    }

    const handleChangeFilter = name => value => {
        setFilter({ ...filter, [name]: value });
    }

    return (
        <Fragment>
            <TitleAndBreadcrumbs title="System Master" breads={[ "Master", "System Master" ]} />
            <ExpansionPanel title="Filter" defaultExpanded>
                <Grid container className={classes.filterContent} justify="flex-start" alignItems="center">
                    <Grid item>
                        <div className={classes.formControl}>
                            <Select 
                                label="Category"
                                placeholder="Category"
                                inputId="cat-react"
                                url="?op=system-cat"
                                value={filter.cat}
                                handler={handleChangeFilter('cat')}
                                ismulti={false}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.formControl}>
                            <Select 
                                label="Sub Category"
                                placeholder="Sub Category"
                                inputId="sub-react"
                                url="?op=system-sub"
                                value={filter.sub}                           
                                handler={handleChangeFilter('sub')}                                
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify="flex-end" spacing={2}>
                            <Grid item>
                                <Button onClick={handleReset} variant="contained" color="default" size="medium" >
                                    <CancelIcon className={classes.leftIcon} />
                                    Reset
                                </Button> 
                            </Grid>
                            <Grid item>
                                <Button onClick={handleApply} variant="contained" color="primary" size="medium" >
                                    <SearchIcon className={classes.leftIcon} />
                                    Apply
                                </Button> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ExpansionPanel>
            <Table columns={columns} columnKeys={keys} url={searchUrl} />
        </Fragment>
    )
}