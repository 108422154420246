import React, { Fragment, useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Select from '../core/Select'

import { makeStyles, } from '@material-ui/core/styles'
import TitleAndBreadcrumbs from '../core/TitleAndBreadcrumbs'
import ExpansionPanel from '../core/ExpansionPanel'
import Table from '../core/table'
import { API_URL } from '../utils/Constant'
import SearchIcon from '@material-ui/icons/Search'
import CancelIcon from '@material-ui/icons/Cancel'
import { useFetch } from '../utils/Fetch'

const useStyles = makeStyles(theme => ({ 
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        marginRight: theme.spacing(5),
        minWidth: 200,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default () => {
    const baseUrl = API_URL.GALLERY
    const classes = useStyles()
    const [searchUrl, setSearchUrl] = useState(baseUrl)
    const [filter, setFilter] = useState({ is_show: '', category: '' })
    const cbxIsShow = [
        {
            value: "0",
            label: "Hidden"
        },
        {
            value: "1",
            label: "Shown"
        }
    ]
    const [cbxCategory, setCbxCategory] = useState([])
    const { data: cbx } = useFetch(API_URL.COMBO + '?op=gallery-category')

    useEffect(() => {
        if(cbx.status) {
            setCbxCategory(cbx.data)
        }
    }, [cbxCategory, cbx])

    const keys = [ "id" ]
    const columns = [
        { label: 'Category', field: 'category', type: 'select', 
            options: cbxCategory
        },
        { label: 'Photo', field: 'photo', type: 'image', 
            options: {
                width: '100%',
                height: 150,
            } 
        },
        { label: 'Status', field: 'is_show', type: 'select', 
            options: cbxIsShow
        },
    ]

    const handleApply = () => {
        let url = baseUrl + "?", param = ''

        let is_show = filter.is_show.value || ''
        let category = filter.category.value || ''
        if(is_show) {
            param += `is_show=${is_show}`
        }
        if(category) {
            if(param) {
                param += '&'
            }
            param += `category=${category}`
        }
        setSearchUrl(url + param)
    }

    const handleReset = () => {
        setFilter({ is_show: '', category: '' })
        setSearchUrl(baseUrl)
    }

    const handleChangeFilter = name => event => {
        if(event.target) {
            setFilter({ ...filter, [name]: event.target.value });
        } else {
            setFilter({ ...filter, [name]: event });
        }
    }

    return (
        <Fragment>
            <TitleAndBreadcrumbs title="Gallery" breads={[ "Content", "Gallery" ]} />
            <ExpansionPanel title="Filter" defaultExpanded>
                <Grid container className={classes.filterContent} justify="flex-start" alignItems="center">
                    <Grid item xs={3}>
                        <div className={classes.formControl}>
                            <Select 
                                label="Category"
                                placeholder="Category"
                                inputId="cat-react"
                                url="?op=gallery-category&all=true"
                                value={filter.category}
                                handler={handleChangeFilter('category')}
                                ismulti={false}
                            />                            
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.formControl}>                        
                            <Select 
                                label="Status"
                                placeholder="Status"
                                inputId="is-show-react"
                                value={filter.is_show}
                                handler={handleChangeFilter('is_show')}
                                ismulti={false}
                                options={cbxIsShow}
                            />
                        </div>
                    </Grid>                    
                    <Grid item xs={12}>
                        <Grid container justify="flex-end" spacing={2}>
                            <Grid item>
                                <Button onClick={handleReset} variant="contained" color="default" size="medium" >
                                    <CancelIcon className={classes.leftIcon} />
                                    Reset
                                </Button> 
                            </Grid>
                            <Grid item>
                                <Button onClick={handleApply} variant="contained" color="primary" size="medium" >
                                    <SearchIcon className={classes.leftIcon} />
                                    Apply
                                </Button> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ExpansionPanel>
            <Table columns={columns} columnKeys={keys} url={searchUrl} />
        </Fragment>
    )
}