import React, { Fragment, useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import Select from '../core/Select'
import { makeStyles, } from '@material-ui/core/styles'
import TitleAndBreadcrumbs from '../core/TitleAndBreadcrumbs'
import ExpansionPanel from '../core/ExpansionPanel'
import Table from '../core/table'
import { API_URL } from '../utils/Constant'
import SearchIcon from '@material-ui/icons/Search'
import CancelIcon from '@material-ui/icons/Cancel'

import { useFetch } from '../utils/Fetch'

const useStyles = makeStyles(theme => ({ 
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        marginRight: theme.spacing(5),
        minWidth: 200,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default ({ location }) => {

    let client_id = ''
    if(location.search) {
        const p = new URLSearchParams(location.search)
        const c = p.get('client_id')
        if(c) {
            client_id = { value: c, label: '' }
        }
    }

    const baseUrl = API_URL.PORTFOLIO_PROJECT
    const classes = useStyles()
    const [searchUrl, setSearchUrl] = useState(baseUrl + (client_id ? `?portfolio_client_id=${client_id.value}`:``))
    const [filter, setFilter] = useState({ client_id: client_id, project_name: '', project_technology: '', is_show: '' })
    const cbxIsActive = [
        {
            value: "N",
            label: "Hidden"
        },
        {
            value: "Y",
            label: "Shown"
        }
    ]
    const [cbxClient, setCbxClient] = useState([])
    const { data: cbx } = useFetch(API_URL.COMBO + '?op=portfolio-client')
    
    useEffect(() => {
        if(cbx.status) {
            setCbxClient(cbx.data)
        }
    }, [cbxClient, cbx])

    const keys = [ "id" ]
    const columns = [
        { label: 'Client', field: 'portfolio_client_id', type: 'select', 
            options: cbxClient
        },
        { label: 'Seq', field: 'seq' },
        { label: 'Project Name', field: 'project_name' },
        { label: 'Project Description', field: 'project_desc' },
        { label: 'Project Technology', field: 'project_technology' },        
        { label: 'Logo', field: 'project_logo', type: 'image', 
            options: {
                width: '100%',
                height: 150,
            } 
        },
        { label: 'Status', field: 'is_show', type: 'select', 
            options: cbxIsActive
        },
    ]

    const handleApply = () => {
        let url = baseUrl + "?", param = ''

        let { client_id, project_name, project_technology, is_show } = filter
        if(client_id) {
            param += `portfolio_client_id=${client_id.value}`
        }
        if(project_name) {
            if(param !== '') {
                param += '&'
            }
            param += `project_name=${project_name}`
        }
        if(project_technology) {
            if(param !== '') {
                param += '&'
            }
            param += `project_technology=${project_technology}`
        }
        if(is_show) {
            if(param !== '') {
                param += '&'
            }
            param += `is_show=${is_show.value}`
        }
        setSearchUrl(url + param)
    }

    const handleReset = () => {
        setFilter({ client_id: '', project_name: '', project_technology: '', is_show: '' })
        setSearchUrl(baseUrl)
    }

    const handleChangeFilter = name => event => {
        if(event.target) {
            setFilter({ ...filter, [name]: event.target.value });
        } else {
            setFilter({ ...filter, [name]: event });
        }
    }

    return (
        <Fragment>
            <TitleAndBreadcrumbs title="Portfolio Project" breads={[ "Content", "Portfolio Client", "Portfolio Project" ]} />
            <ExpansionPanel title="Filter" defaultExpanded>
                <Grid container className={classes.filterContent} justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                        <div className={classes.formControl}>
                            <Select 
                                label="Client"
                                placeholder="Client"
                                inputId="cat-react"
                                url="?op=portfolio-client&all=true"
                                value={filter.client_id}
                                handler={handleChangeFilter('client_id')}
                                ismulti={false}
                            />                            
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.formControl}>
                            <TextField 
                                label="Project Name"
                                value={filter.project_name}
                                onChange={handleChangeFilter('project_name')}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.formControl}>
                            <TextField 
                                label="Project Technology"
                                value={filter.project_technology}
                                onChange={handleChangeFilter('project_technology')}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.formControl}>
                            <Select 
                                label="Status"
                                placeholder="Status"
                                inputId="is-show-react"
                                value={filter.is_show}
                                handler={handleChangeFilter('is_show')}
                                ismulti={false}
                                options={cbxIsActive}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify="flex-end" spacing={2}>
                            <Grid item>
                                <Button onClick={handleReset} variant="contained" color="default" size="medium" >
                                    <CancelIcon className={classes.leftIcon} />
                                    Reset
                                </Button> 
                            </Grid>
                            <Grid item>
                                <Button onClick={handleApply} variant="contained" color="primary" size="medium" >
                                    <SearchIcon className={classes.leftIcon} />
                                    Apply
                                </Button> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ExpansionPanel>
            <Table columns={columns} columnKeys={keys} url={searchUrl} />
        </Fragment>
    )
}