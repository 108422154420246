import React from 'react'
import clsx from 'clsx'
import { makeStyles, AppBar, Toolbar, IconButton, Typography, CardMedia, Button, Slide } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/Notifications'
import { DrawerWidth } from './Drawer'
import Logo from '../../assets/images/logo.png'
import { AuthService } from '../auth'

const drawerWidth = DrawerWidth;

const useStyles = makeStyles(theme => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      paddingRight: 24,   // keep right padding when drawer closed
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    logo: {
      height: 50,
      width: 200,
    }
}))

export default ({ open, handleDrawerOpen, history }) => {
    const classes = useStyles()

    const handleSignOut = () => {
      let Auth = new AuthService()
      Auth.logout()
      history.replace('/login')
    }
    
    return <AppBar position="fixed" className={clsx(classes.appBar, open && classes.appBarShift)} color="secondary">
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
          { !open ? 
            <Slide in={!open} direction="left" timeout={1000} mountOnEnter unmountOnExit>
              <CardMedia image={Logo} className={classes.logo} />
            </Slide> :
            <Slide in={!open} direction="left" timeout={300} mountOnEnter unmountOnExit>
              <CardMedia image={Logo} className={classes.logo} />
            </Slide> }
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>            
          </Typography>          
          {/* <IconButton color="inherit" style={{ marginRight: 10 }}>
            <Badge badgeContent={4} color="primary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <Button variant="outlined" color="primary" className={classes.button} onClick={handleSignOut}>
            Sign Out
          </Button>
        </Toolbar>
    </AppBar>
}