import React from 'react'
import StyledTableCell from './TableCell'
import { STATIC_URL } from '../../utils/Constant'
// import Button from '@material-ui/core/Button'

const CellBody = ({ type, options, label, data, ...rest }) => {
    return (
        <StyledTableCell {...rest}>
            { type === 'image' ?
                <div style={{ textAlign: 'center' }}>
                    <img src={`${STATIC_URL}${data}`} 
                        alt={label} 
                        style={{ width: (options.width * 0.5), height: (options.height * 0.5) }}
                    />
                </div>
                :
                data
            }
        </StyledTableCell>
    )
}

export default CellBody