import { useState, useEffect } from 'react'
import { API_URL } from './Constant'
import { AuthService } from '../auth'

const Auth = new AuthService();

function useFetch(url) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({ data: {}, message: "", status: false });
    const token = Auth.getToken();

    useEffect(() => {
        let unmounted = false;

        const handleFetchResponse = response => {
            if (unmounted) return data;
            setIsLoading(false);
            
            return response.ok && response.json ? response.json() : data;
        };

        const fetchData = async () => {
            setIsLoading(true);
            return await fetch(url, { 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(handleFetchResponse)
            .catch(handleFetchResponse);
        };

        if (url && !unmounted) {
            fetchData().then(data => {
                !unmounted && setData(data)
            });
        }

        // eslint-disable-next-line
    },  [url]);
    
    return { isLoading, data };
}

const doFetch = async (url, body, method) => {
    return await fetch(url, {
        method,
        headers: {
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${Auth.getToken()}`
        },
        body
    })
    .then(resp => {        
        let result;
        if(!resp.ok) {
            result = { ...resp.json(), statusCd: resp.status }            
        } else {
            result = resp.json()
        }
        return Promise.resolve(result)
    })
    .catch((error) => {
        return Promise.resolve({ status: false, message: "oops, something happened...", statusCd: null })
    })
}

function useFetchMeta(param) {
    const { data: { data: metaData, status, message }, isLoading } = 
        useFetch(API_URL.PAGE_META + "?p=" + encodeURIComponent(param))
    return { metaData, status, message, isLoading }
}

export { useFetch, useFetchMeta, doFetch }