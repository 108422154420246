import React, { Fragment, useState } from 'react'
import { Grid, Button, TextField,  } from '@material-ui/core'
import { makeStyles, } from '@material-ui/core/styles'
import TitleAndBreadcrumbs from '../core/TitleAndBreadcrumbs'
import ExpansionPanel from '../core/ExpansionPanel'
import Table from '../core/table'
import { API_URL } from '../utils/Constant'
import SearchIcon from '@material-ui/icons/Search'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles(theme => ({ 
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        marginRight: theme.spacing(5),
        minWidth: 200,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default () => {
    const baseUrl = API_URL.PAGE_META
    const classes = useStyles()
    const [searchUrl, setSearchUrl] = useState(baseUrl)
    const [filter, setFilter] = useState({ url: '' })

    const keys = [ "url" ]
    const columns = [
        { label: 'URL', field: 'url', },
        { label: 'Description', field: 'description' },
        { label: 'Keywords', field: 'keywords' },
        { label: 'Robots', field: 'robots', type: 'select',
            options: [ 
                { label: 'Index & Follow', value: 'index,follow' },
                { label: 'Index & No Follow', value: 'index,nofollow' },
                { label: 'No Index & Follow', value: 'noindex,follow' },
                { label: 'No Index & No Follow', value: 'noindex,nofollow' },
            ] 
        },
    ]

    const handleApply = () => {
        let url = filter.url || ''
        if(url !== '') {
            setSearchUrl(baseUrl + `?url=${url}`)
        }
    }

    const handleReset = () => {
        setFilter({ url: '' })
        setSearchUrl(baseUrl)
    }

    const handleChangeFilter = name => event => {
        setFilter({ ...filter, [name]: event.target.value });
    }

    return (
        <Fragment>
            <TitleAndBreadcrumbs title="Pages Meta" breads={[ "Pages Meta" ]} />
            <ExpansionPanel title="Filter" defaultExpanded>
                <Grid container className={classes.filterContent} justify="flex-start" alignItems="center">
                    <Grid item>
                        <div className={classes.formControl}>
                            <TextField 
                                label="Url"
                                value={filter.url}
                                onChange={handleChangeFilter('url')}
                                autoFocus
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </Grid>                    
                    <Grid item xs={12}>
                        <Grid container justify="flex-end" spacing={2}>
                            <Grid item>
                                <Button onClick={handleReset} variant="contained" color="default" size="medium" >
                                    <CancelIcon className={classes.leftIcon} />
                                    Reset
                                </Button> 
                            </Grid>
                            <Grid item>
                                <Button onClick={handleApply} variant="contained" color="primary" size="medium" >
                                    <SearchIcon className={classes.leftIcon} />
                                    Apply
                                </Button> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ExpansionPanel>
            <Table columns={columns} columnKeys={keys} url={searchUrl} />
        </Fragment>
    )
}