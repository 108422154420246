import React, { useState, Fragment } from 'react'
import { Button, Grid } from '@material-ui/core'
import { makeStyles, } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import CancelIcon from '@material-ui/icons/Cancel'

import { API_URL } from '../utils/Constant'
import TextField from '@material-ui/core/TextField'
import TitleAndBreadcrumbs from '../core/TitleAndBreadcrumbs'
import ExpansionPanel from '../core/ExpansionPanel'
import Table from '../core/tableUser'

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        marginRight: theme.spacing(5),
        minWidth: 200,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default () => {
    const classes = useStyles()
    const [filter, setFilter] = useState({ username: '', fullname: ''})
    const [searchUrl, setSearchUrl] = useState(API_URL.USER)

    const keys = [ "username", "fullname", "email" ]
    const columns = [
        { label: 'Username', field: 'username', },
        { label: 'Name', field: 'fullname' },
        { label: 'Email', field: 'email' },
        { label: 'Address', field: 'address' },
    ]

    const handleApply = () => {
        let fullname = filter.fullname || ''
        if(fullname !== '') {
            setSearchUrl(API_URL.USER + `?fullname=${fullname}`)
        }
    }

    const handleReset = () => {
        setFilter({ username: '', fullname: '' })
        setSearchUrl(API_URL.USER)
    }

    const handleChangeFilter = name => event => {
        setFilter({ ...filter, [name]: event.target.value });
    }
    return (
        <Fragment>
            <TitleAndBreadcrumbs title="User Master" breads={[ "Master", "User Master" ]} />
            <ExpansionPanel title="Filter" defaultExpanded>
                <Grid container className={classes.filterContent} justify="flex-start" alignItems="center">
                    {/* <Grid item>
                        <div className={classes.formControl}>
                            <TextField 
                                label="Username"
                                value={filter.fullname}
                                onChange={handleChangeFilter('username')}
                                autoFocus
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </Grid> */}
                    <Grid item>
                        <div className={classes.formControl}>
                        <TextField 
                                label="Name"
                                value={filter.fullname}
                                onChange={handleChangeFilter('fullname')}
                                autoFocus
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify="flex-end" spacing={2}>
                            <Grid item>
                                <Button onClick={handleReset} variant="contained" color="default" size="medium" >
                                    <CancelIcon className={classes.leftIcon} />
                                    Reset
                                </Button> 
                            </Grid>
                            <Grid item>
                                <Button onClick={handleApply} variant="contained" color="primary" size="medium" >
                                    <SearchIcon className={classes.leftIcon} />
                                    Apply
                                </Button> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ExpansionPanel>
            <Table columns={columns} columnKeys={keys} url={searchUrl} />
        </Fragment>
    )
}