import React from 'react';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export default ({ message, open, handleNo, handleYes }) => {
    return (
        <Dialog
            open={open}
            onClose={handleNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
            >
            <DialogTitle id="alert-dialog-title">{'Confirmation'}</DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleNo} variant="contained" color="default">
                    No
                </Button>
                <Button onClick={handleYes} variant="contained" color="primary" autoFocus>
                    YES
                </Button>
            </DialogActions>
        </Dialog>
    )
}