import { API_URL } from '../utils/Constant'

const url = API_URL.REVIEW

const createHeader = () => {
    const token = sessionStorage.getItem('id_token')
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
    }
}

const handleSuccess = async response => {
    try {
        const data = await response.json()
        if (response.status >= 200 && response.status < 300) {
            // if response http status code between 200 ~ 299 consider response as success normally
            return { status: data.status, data: data.data, message: data.message, ...data }
        } else {
            // else consider as failed, either bad request or internal server error
            return { status: false, message: data.message, ...data }
        }
    } catch (error) {
        console.error('[api-review] parse error: ', error)
        return { status: false, data: null, message: 'Oops, Something went wrong...' }
    }
}

const handleError = error => {
    console.error('[api-review] request error: ', error)
    return { status: false, data: null, message: 'Oops, Something went wrong...' }
}

export default {

    async search (params) {
        const query = []
        for (const prop in params) {
            if (params[prop] != null && params[prop] !== '' && typeof params[prop] != 'undefined' ) {
                query.push(`${prop}=${params[prop]}`)
            }
        }
        const request = await fetch(`${url}?${query.join('&')}`, {
            method: 'get',
            headers: createHeader(),
        })
        .then(async response => await handleSuccess(response))
        .catch(error => handleError(error))

        return request
    },

    async create (data) {
        delete data.id
        const request = await fetch(url, {
            method: 'post',
            headers: createHeader(),
            body: JSON.stringify(data)
        })
        .then(async response => await handleSuccess(response))
        .catch(error => handleError(error))

        return request
    },

    async update (data) {
        const request = await fetch(url, {
            method: 'put',
            headers: createHeader(),
            body: JSON.stringify(data)
        })
        .then(async response => await handleSuccess(response))
        .catch(error => handleError(error))

        return request
    },

    async delete (data) {
        const ids = data.map(item => ({ id: item.id }))
        const request = await fetch(url, {
            method: 'delete',
            headers: createHeader(),
            body: JSON.stringify(ids)
        })
        .then(async response => await handleSuccess(response))
        .catch(error => handleError(error))

        return request
    }
}