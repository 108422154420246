export const ENV = () => {
    return process.env.NODE_ENV
}

export const BASE_API_URL = ENV() === 'production' ? 'https://api-comprof.ptbsp.com' : 'http://localhost:8001';
export const STATIC_URL = BASE_API_URL + "/static";
export const API_URL = {
    AUTH: BASE_API_URL + "/api/auth",
    AUTH_VALID: BASE_API_URL + "/api/auth-valid",
    AUTH_LOGIN: BASE_API_URL + "/api/auth/login",
    SYSTEM: BASE_API_URL + "/api/system",
    COMBO: BASE_API_URL + "/api/combo",
    PAGE_META: BASE_API_URL + "/api/pagemeta",
    CLIENT: BASE_API_URL + "/api/client",
    PRODUCT: BASE_API_URL + "/api/product",
    USER: BASE_API_URL + "/api/user",
    REVIEW: BASE_API_URL + "/api/review",
    TEAM: BASE_API_URL + "/api/team",
    CONTACT_US: BASE_API_URL + "/api/contactus",
    TESTIMONY: BASE_API_URL + "/api/testimony",
    BLOG : BASE_API_URL + "/api/blog",
    CAREER : BASE_API_URL + "/api/career",
    GALLERY : BASE_API_URL + "/api/gallery",
    INSTRUCTOR : BASE_API_URL + "/api/instructor",
    PORTFOLIO_CLIENT : BASE_API_URL + "/api/portfolio/client",
    PORTFOLIO_PROJECT : BASE_API_URL + "/api/portfolio/project",
};
