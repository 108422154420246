import React, { useEffect, Fragment, useState, createRef } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Draggable from 'react-draggable'
import { STATIC_URL } from '../../utils/Constant'

import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import Popover from '@material-ui/core/Popover'
import CardMedia from '@material-ui/core/CardMedia'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Tooltip from '@material-ui/core/Tooltip';
//import  from '@material-ui/core/'

import AddAPhoto from '@material-ui/icons/AddAPhoto'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Cancel'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/styles'

// import Select from '../Select'

function PaperComponent(props) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

  
const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6" style={{ textTransform: 'capitalize' }}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles(theme => ({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1, 0, 1)
    },
    txtfieldImage: {
        marginTop: theme.spacing(1)
    },
    button: {
        marginLeft: theme.spacing(1)
    },
    rootImg: {
        alignItems: 'center',
        display: 'flex',
        minWidth: 500
    }
}))


export default ({ title, isOpen, handleSave, handleClose, columns, columnKeys, data, handleData }) => {
    const [labelWidth, setLabelWidth] = useState(0);
    const classes = useStyles()
    const inputLabel = createRef()

    // image
    const [imgLabel, setImgLabel] = useState({})
    const [imgData, setImgData] = useState({})
    const [options, setOptions] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [nameEl, setNameEl] = useState(null)

    useEffect(() => {
        setLabelWidth(inputLabel.current != null ? inputLabel.current.offsetWidth : 0);
        
        const prepareLabel = async () => {
            let imgLabel = {}
            if(Object.entries(data).length > 0 && data.constructor === Object) {
                let imageTypes = columns.filter(f => f.type === 'image')                
                await imageTypes.forEach(fe => {  
                    let fileName = data[fe.field].replace(/^.*[\\/]/, '')
                    imgLabel = { ...imgLabel, [fe.field]: fileName }
                })                
            } 
            return Promise.resolve(imgLabel)
        }

        const fetchImage = async (field, fileName) => {
            return await fetch(STATIC_URL + fileName)
            .then(r => r.blob())
            .then(blob => {
                let reader = new FileReader()
                reader.onload = loadImg(field)
                reader.readAsDataURL(blob)                    
            })
        }

        const loadImg = field => e => {
            setImgData(prevData => ({ ...prevData, [field]: e.target.result}))                  
        }

        const prepareImage = () => {
            if(Object.entries(data).length > 0 && data.constructor === Object) {
                let imageTypes = columns.filter(f => f.type === 'image')
                imageTypes.forEach(fe => {
                    fetchImage(fe.field, data[fe.field])
                })
            }                   
        }  

        if(isOpen) {
            prepareLabel().then(p => setImgLabel(p))
            prepareImage()
        }
        // eslint-disable-next-line
    },  [isOpen])

    const handleAddPhoto = name => ({ target }) => {        
        setImgLabel({ ...imgLabel, [name]: target.files[0].name })

        let reader = new FileReader()
        reader.readAsDataURL(target.files[0])
        reader.onload = (e) => {
            try {                
                let result = e.target.result
                handleData(name)(result)
                setImgData({ ...imgData, [name]: result})
            } catch {

            }
        }
    }

    const clearImg = (name) => {
        setImgLabel({ ...imgLabel, [name]: '' })
    }

    const handlePopover = (name, options) => event => {
        setAnchorEl(event.currentTarget);
        setNameEl(name)
        setOptions(options)
    }
    
    const handlePopoverClose = () => {
        setOptions(null)
        setAnchorEl(null);
        setNameEl(null)
    }

    const handlePreCloseOrSave = (nextAction) => (event) => {
        let promise = nextAction()
        if(promise) {
            // this is a save operation
            promise
            .then(success => {
                if(success) {
                    // only clear when save went successfully
                    setImgLabel({})
                    setImgData({})
                }                
            })
        } else {
            setImgLabel({})
            setImgData({})
        }        
    }
    
    const imgOpen = Boolean(anchorEl)
    const img = imgData[nameEl]
    const width = options ? options.width : 100
    const height = options ? options.height : 100

    return (
        <Dialog
            open={isOpen}
            onClose={handlePreCloseOrSave(handleClose)}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" onClose={handlePreCloseOrSave(handleClose)}>
                { title + ' Data'}
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: 200 }}>
                { columns.map((clm, index) => {
                    let type = clm.type ? clm.type : 'text'
                    if(title === 'edit' && type === 'password') {
                        return (
                            <TextField 
                                key={index}
                                autoFocus={index === 0}
                                required={columnKeys.indexOf(clm.field) > -1}
                                // disabled={columnKeys.indexOf(clm.field) > -1 && title === 'edit'}
                                margin="dense"
                                id={clm.field}
                                label={clm.label}
                                type={ type }
                                variant="outlined"
                                fullWidth
                                onChange={handleData(clm.field)}
                                value={data[clm.field] || ''}
                            />                        
                        )
                    }
                    if(type === 'select') {
                        return (
                            <FormControl variant="outlined" fullWidth key={index} className={classes.formControl}>
                                <InputLabel ref={inputLabel} margin="dense" htmlFor={clm.field}>
                                    { clm.label }
                                </InputLabel>
                                <Select 
                                    value={data[clm.field] || ''}
                                    onChange={handleData(clm.field)}
                                    input={
                                        <OutlinedInput 
                                            margin="dense"
                                            labelWidth={labelWidth}
                                            name={clm.field} 
                                            id={clm.field}
                                        />
                                    }
                                    >
                                    <MenuItem value=""></MenuItem>
                                    { clm.options.map((one, index) => {
                                        return (
                                            <MenuItem value={one.value} key={index}>{one.label}</MenuItem>                                        
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        )
                    } else if(type === 'image') {
                        return (
                            <Fragment key={index}>
                                <TextField
                                    className={classes.txtfieldImage}                                    
                                    required={columnKeys.indexOf(clm.field) > -1}
                                    // disabled={columnKeys.indexOf(clm.field) > -1 && title === 'edit'}
                                    id={clm.field}
                                    label={`${clm.label} - (${clm.options.width}x${clm.options.height})`}
                                    fullWidth
                                    type="text"
                                    variant="outlined"                                
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: 
                                            <InputAdornment position="end">
                                                { imgLabel[clm.field] &&
                                                <ButtonGroup color="primary" aria-label="button group">
                                                    <Tooltip title="Clear" aria-label="Clear">
                                                        <Button variant="contained" color="default" component="span" className={classes.button} onClick={() => clearImg(clm.field)}>
                                                            <CancelIcon />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="View" aria-label="View">
                                                        <Button 
                                                            variant="contained" 
                                                            color="primary" 
                                                            component="span" 
                                                            className={classes.button}                                                     
                                                            aria-owns={imgOpen ? 'mouse-over-popover' : undefined}
                                                            aria-haspopup="true"
                                                            onClick={handlePopover(clm.field, clm.options)}                                    
                                                            >
                                                            <SearchIcon />
                                                        </Button>
                                                    </Tooltip>
                                                </ButtonGroup>
                                                }
                                                { !imgLabel[clm.field] &&
                                                <label htmlFor={'hidden_' + clm.field}>
                                                    <Tooltip title="Browse Image" aria-label="Browse">
                                                        <Button variant="outlined" color="primary" component="span" className={classes.button}>
                                                            <AddAPhoto />
                                                        </Button>
                                                    </Tooltip>
                                                </label>
                                                }
                                            </InputAdornment>
                                    }}                                
                                    value={imgLabel[clm.field] || ''}
                                />
                                <input 
                                    accept="image/jpeg, image/png"
                                    id={'hidden_' + clm.field}
                                    type="file"                                
                                    style={{ display: 'none' }}
                                    onChange={handleAddPhoto(clm.field)}
                                />                                 
                            </Fragment>
                        )
                    }
                    else if(type === 'text' || type === 'number') {
                        return (
                            <TextField 
                                key={index}
                                autoFocus={index === 0}
                                required={columnKeys.indexOf(clm.field) > -1}
                                // disabled={columnKeys.indexOf(clm.field) > -1 && title === 'edit'}
                                hidden= {true}
                                margin="dense"
                                id={clm.field}
                                label={clm.label}
                                type={ type }
                                variant="outlined"
                                fullWidth
                                InputLabelProps={type === 'number' ? {
                                    shrink: true,
                                } : {}}
                                onChange={handleData(clm.field)}
                                value={data[clm.field] || ''}
                            />                        
                        )
                    }
                    else return null
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={handlePreCloseOrSave(handleClose)} color="default" variant="contained">
                    <CancelIcon className={classes.leftIcon} />
                    Cancel
                </Button>
                <Button onClick={handlePreCloseOrSave(handleSave)} color="primary" variant="contained" style={{ marginRight: 10 }}>
                    <CheckIcon className={classes.leftIcon}/>
                    Save
                </Button>
            </DialogActions>
            <Popover
                id={`mouse-over-popover`}
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={imgOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                >
                {/* <div style={{ width: 200, height: 200}}>
                    <img src={img} alt="Preview" style={{ maxWidth: '100%', maxHeight: '98%'}}/>
                </div> */}
                <div style={{ width, height }}>
                    { imgOpen && <CardMedia image={img} style={{ width, height }}/> }
                </div>
            </Popover>
        </Dialog>
    )
}