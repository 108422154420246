import DashboardIcon from '@material-ui/icons/Dashboard'
import PagesIcon from '@material-ui/icons/Pages'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import BusinessIcon from '@material-ui/icons/Business'
import ShopTwo from '@material-ui/icons/ShopTwo'

const MenuList = [
    { id: "0", name: "Dashboard",   path: "/dashboard", icon: DashboardIcon,    child: [] },
    { id: "1", name: "Master",      path: "",           icon: LibraryBooksIcon, child: [ 
        { id: "1.1", name: "System Master", path: "/system" },
        { id: "1.2", name: "User", path: "/user" } 
        ] 
    },
    { 
        id: "2", 
        name: "Content",
        path: "", 
        icon: LibraryBooksIcon,
        child: [ 
            { id: "2.1", name: "Blog", path: "/blog" },
            { id: "2.2", name: "Products", path: "/products" },
            { id: "2.3", name: "Reviews", path: "/reviews"},
            { id: "2.4", name: "Team", path: "/team"}, 
            { id: "2.5", name: "Contact Us", path: "/contactUs"},
            { id: "2.6", name: "Testimony", path: "/testimony"}, 
            { id: "2.7", name: "Career", path: "/career"},
            { id: "2.8", name: "Gallery", path: "/gallery"},
            { id: "2.9", name: "Instructor", path: "/instructor"},
            { id: "2.10", name: "Portfolio Client", path: "/portfolio/client"},
            { id: "2.11", name: "Portfolio Project", path: "/portfolio/project"},
        ] 
    },
    { id: "3", name: "Products",     path: "/product",   icon: ShopTwo,          child: [] },
    { id: "4", name: "Clients",     path: "/client",    icon: BusinessIcon,     child: [] },
    { id: "5", name: "Pages Meta",  path: "/pagesMeta", icon: PagesIcon,        child: [] }
]

export default MenuList 