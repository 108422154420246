import React, { Fragment, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Select from '../core/Select'
import TextField from '@material-ui/core/TextField'

import { makeStyles, } from '@material-ui/core/styles'
import TitleAndBreadcrumbs from '../core/TitleAndBreadcrumbs'
import ExpansionPanel from '../core/ExpansionPanel'
import Table from '../core/table'
import { API_URL } from '../utils/Constant'
import SearchIcon from '@material-ui/icons/Search'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles(theme => ({ 
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        marginRight: theme.spacing(5),
        minWidth: 200,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default ({ history }) => {
    const baseUrl = API_URL.PORTFOLIO_CLIENT
    const classes = useStyles()
    const [searchUrl, setSearchUrl] = useState(baseUrl)
    const [filter, setFilter] = useState({ name: '', is_show: '' })
    const cbxIsShow = [
        {
            value: "N",
            label: "Hidden"
        },
        {
            value: "Y",
            label: "Shown"
        }
    ] 

    const keys = [ "id" ]
    const columns = [
        { label: 'Client Name', field: 'client_name' },
        { label: 'Description', field: 'portfolio_description' },
        { label: 'Seq', field: 'seq' },
        { label: 'Status', field: 'is_show', type: 'select', 
            options: cbxIsShow
        },
    ]

    const handleApply = () => {
        let url = baseUrl + "?"

        let name = filter.name || ''
        let is_show = filter.is_show.value || ''

        if(name !== '') {
            url += `name=${name}`
        }
        if(is_show !== '') {
            if(url !== '') {
                url += '&'
            }
            url += `is_show=${is_show}`
        }
        setSearchUrl(url)
    }

    const handleReset = () => {
        setFilter({ is_show: '', name: '' })
        setSearchUrl(baseUrl)
    }

    const handleChangeFilter = name => event => {
        if(event.target) {
            setFilter({ ...filter, [name]: event.target.value })
        } else {
            setFilter({ ...filter, [name]: event })
        }
    }

    return (
        <Fragment>
            <TitleAndBreadcrumbs title="Portfolio Client" breads={[ "Content", "Portfolio Client" ]} />
            <ExpansionPanel title="Filter" defaultExpanded>
                <Grid container className={classes.filterContent} justify="flex-start" alignItems="center">
                    <Grid item>
                        <div className={classes.formControl}>
                            <TextField 
                                label="Name"
                                value={filter.name}
                                onChange={handleChangeFilter('name')}
                                autoFocus
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.formControl}>                        
                            <Select 
                                label="Status"
                                placeholder="Status"
                                inputId="is-show-react"
                                value={filter.is_show}
                                handler={handleChangeFilter('is_show')}
                                ismulti={false}
                                options={cbxIsShow}
                            />
                        </div>
                    </Grid>                    
                    <Grid item xs={12}>
                        <Grid container justify="flex-end" spacing={2}>
                            <Grid item>
                                <Button onClick={handleReset} variant="contained" color="default" size="medium" >
                                    <CancelIcon className={classes.leftIcon} />
                                    Reset
                                </Button> 
                            </Grid>
                            <Grid item>
                                <Button onClick={handleApply} variant="contained" color="primary" size="medium" >
                                    <SearchIcon className={classes.leftIcon} />
                                    Apply
                                </Button> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ExpansionPanel>
            <Table columns={columns} 
                columnKeys={keys} 
                url={searchUrl} 
                urlDetail="/portfolio/project" 
                detailKeys={["client_id"]} 
                history={history} />
        </Fragment>
    )
}