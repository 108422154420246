import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'

export default ({ title, breads }) => {
    return <Grid container justify="space-between" alignItems="center">
        <Grid item>
            <Typography variant="h5">{ title }</Typography>
        </Grid>
        <Grid item>
            { breads && breads.length > 1 && 
            <Breadcrumbs aria-label="Breadcrumb">
                { breads.map((ob, index) => {
                    let color = index+1 === breads.length ? "textPrimary" : "textSecondary"
                    return <Typography color={color} key={index} variant="subtitle2">
                        { ob }
                    </Typography>                
                    })
                }                
            </Breadcrumbs>
            }
        </Grid>
    </Grid>
}