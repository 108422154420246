import React, { useState } from 'react';
import { Typography, Link, CssBaseline, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Navbar from './Navbar'
import { CustomDrawer } from './Drawer'

function Copyright({ className }) {
    return (
        <Typography variant="body2" color="textSecondary" align="center" className={className}>
        { '© Copyright ' + new Date().getFullYear() + ' ' }
        <Link color="inherit" href="https://ptbsp.com">
            PT. Bee Solution Partners
        </Link>
        { '.' }
        </Typography>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    content: {
        padding: theme.spacing(2, 0),
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    copyright: {
        padding: theme.spacing(2, 0)
    }
}))

function Layout({ children }) {
    const { props: { location, history } } = children;
    const classes = useStyles()
    const [open, setOpen] = useState(true)

    const handleDrawerClose = () => {
        setOpen(false)
    }
    const handleDrawerOpen = () => {
        setOpen(true)
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Navbar handleDrawerOpen={handleDrawerOpen} open={open} history={history} />
            <CustomDrawer handleDrawerClose={handleDrawerClose} open={open} location={location} />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} maxWidth="xl">
                    { children }
                    <Copyright className={classes.copyright}/>
                </Container>
            </main>
        </div>
    )
}

export { Copyright, Layout }