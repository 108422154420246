import { TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
        fontSize: '1.2em',
        fontWeight: 'bold',
        borderRight: '1px solid rgba(244, 244, 244, 1)'
    },
    body: {
        borderRight: '1px solid rgba(200, 200, 200, 1)',
        borderBottom: '1px solid rgba(200, 200, 200, 1)'
    }
}))(TableCell);

export default StyledTableCell