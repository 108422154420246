import React, { useEffect, useState, useRef, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import { withStyles } from '@material-ui/core/styles'
import Draggable from 'react-draggable'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogContentText from '@material-ui/core/DialogContentText'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'

import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Cancel'

import api from './api'

function PaperComponent(props) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    )
}

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
})

const DialogTitle = withStyles(styles) (props => {
    const { children, classes, onClose } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6" style={{ textTransform: 'capitalize' }}>{children}</Typography>
            {onClose &&
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            }
        </MuiDialogTitle>
    )
})

const useStyles = makeStyles(theme => ({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1, 0, 1)
    },
    button: {
        marginLeft: theme.spacing(1)
    },
}))

const isEmpty = value => {
    if (typeof value === 'string' || value instanceof String) {
        return value.trim().length === 0
    }
    return value === '' || value == null || typeof value == 'undefined'
}

const maxLength = (value, length) => {
    if (typeof value === 'string' || value instanceof String) {
        return value.length <= length
    }
    return true
}

export default ({ mode, open, data, onToast, onClose, onSave }) => {
    const classes = useStyles()
    const dialogRef = useRef()

    // Form Title. Uppercase first letter of mode (add / edit).
    const title = mode.charAt(0).toUpperCase() + mode.slice(1)

    // Form Loading State. Indicate form loading state, should disable most input and button.
    const [loading, setLoading] = useState(false)

    // Form Confirmation State. Show form confirmation before save
    const [formConfirmShow, setFormConfirmShow] = useState(false)

    // Form Data State. Each property name correspond to Data from api.
    const [form, setForm] = useState({
        id: '',
        name: '',
        review: '',
        title: '',
        is_show: '',
    })

    // Form Helper State. Each property name correspond to Form Data.
    // Form Helper State use to display error status & message on input.
    const [helper, setHelper] = useState({
        name: { error: false, message: null },
        review: { error: false, message: null },
        title: { error: false, message: null },
        is_show: { error: false, message: null },
    })

    // Form Rules. Each property name correspond to Form Data.
    // Form Rules use to validate value.
    const rules = {
        name: [
            v => !isEmpty(v) || 'Name should not be empty',
            v => maxLength(v, 100) || 'Name should be less or equal 100 characters',
        ],
        review: [
            v => !isEmpty(v) || 'Review should not be empty',
            v => maxLength(v, 100) || 'Review should be less or equal 100 characters',
        ],
        title: [
            v => !isEmpty(v) || 'Title should not be empty',
            v => maxLength(v, 100) || 'Title should be less or equal 100 characters',
        ],
        is_show: [ v => !isEmpty(v) || 'Show should be selected'],
    }

    // Validate value by each rule correspond to rule name in Form Rules.
    // Validate return null if rule is not found, in that case no need to update Form Helper State.
    const validate = (name, value) => {
        if (rules[name]) {
            for (const rule of rules[name]) {
                const result = rule(value)
                if (result !== true) {
                    return { error: true, message: result }
                }
            }
            return { error: false, message: null }
        } else {
            return null
        }
    }

    // Handle Form Change.
    // Keeping track Form Data & Form Helper State
    const handleFormChange = event => {
        const { name, value } = event.target
        setForm(state => ({ ...state, [name]: value }))

        const result = validate(name, value)
        if (result) {
            // Form Helper State need to update
            setHelper(state => ({ ...state, [name]: result }))
        }
    }

    // Handle Form Validate.
    // Validate all value of Form Data and update Form Helper State
    const handleFormValidate = () => {
        let valid = true
        let infos = {} // Temporary Variable to store Form Helper State
        for (const field in form) {
            const result = validate(field, form[field])
            if (result) {
                // Form Helper State need to update
                infos[field] = result
                if (result.error) {
                    valid = false
                }
            }
        }

        setHelper(infos)
        return valid
    }

    // Handle Form Save, validate Form
    const handleFormSave = async () => {
        if (!handleFormValidate()) return
        setFormConfirmShow(true)
    }

    // Handle Form Confirm Yes. Call API to save
    const handleFormConfirmYes = async () => {
        setFormConfirmShow(false)
        setLoading(true)
        let response
        if (mode === 'add') {
            response = await api.create(form)
        } else {
            response = await api.update(form)
        }
        if (response.status) {
            // Callback to close Form Dialog on Parent Component
            onClose()
            onSave(response)
        }
        // Callback to display Toast message on Parent Component
        onToast({ status: response.status, message: response.message })
        setLoading(false)
    }

    // Handle Form Confirm No. Close form confirmation dialog
    const handleFormConfirmNo = () => {
        setFormConfirmShow(false)
    }

    // Listener to keep Data sync with Form Data
    // Data will differ between add & edit
    useEffect(() => {
        setForm({
            id: data.id || '',
            name: data.name || '',
            review: data.review || '',
            title: data.title || '',
            is_show: data.is_show || '',
        })
        setHelper({
            name: { error: false, message: null },
            review: { error: false, message: null },
            title: { error: false, message: null },
            is_show: { error: false, message: null },
        })
    }, [data])

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                ref={dialogRef}
            >
                <DialogTitle
                    id="draggable-dialog-title"
                    style={{ cursor: 'move' }}
                    onClose={onClose}
                >
                    { `${title} Data` }
                </DialogTitle>
                <DialogContent dividers style={{ minHeight: 200 }}>
                    <TextField
                        autoFocus
                        variant="outlined" margin="dense"  fullWidth
                        type="text" required
                        label="Name" name="name"
                        value={form.name}
                        onChange={handleFormChange}
                        error={helper.name.error}
                        FormHelperTextProps={{ component: "div" }}
                        helperText={
                            <Grid container>
                                <Grid item xs={10}>{helper.name.message}</Grid>
                                <Grid item xs={2} align="right">{form.name.length}/100</Grid>
                            </Grid>
                        }
                        disabled={loading}
                    />
                    <TextField
                        variant="outlined" margin="dense" fullWidth
                        type="text" required multiline rows={3}
                        label="Review" name="review"
                        value={form.review}
                        onChange={handleFormChange}
                        error={helper.review.error}
                        FormHelperTextProps={{ component: "div" }}
                        helperText={
                            <Grid container>
                                <Grid item xs={10}>{helper.review.message}</Grid>
                                <Grid item xs={2} align="right">{form.review.length}/100</Grid>
                            </Grid>
                        }
                        disabled={loading}
                    />
                    <TextField
                        variant="outlined" fullWidth margin="dense"
                        required type="text"
                        label="Title" name="title"
                        value={form.title}
                        onChange={handleFormChange}
                        error={helper.title.error}
                        FormHelperTextProps={{ component: "div" }}
                        helperText={
                            <Grid container>
                                <Grid item xs={10}>{helper.title.message}</Grid>
                                <Grid item xs={2} align="right">{form.title.length}/100</Grid>
                            </Grid>
                        }
                        disabled={loading}
                    />
                    <FormControl
                        required className={classes.formControl}
                        variant="outlined" fullWidth margin="dense"
                        error={helper.is_show.error}
                        disabled={loading}
                    >
                        <InputLabel id="form-show-label">Show</InputLabel>
                        <Select
                            labelId="form-show-label"
                            fullWidth
                            label="Show" name="is_show"
                            value={form.is_show}
                            onChange={handleFormChange}
                            disabled={loading}
                        >
                            <MenuItem value=""><em>Select</em></MenuItem>
                            <MenuItem value="Y">Yes</MenuItem>
                            <MenuItem value="N">No</MenuItem>
                        </Select>
                        <FormHelperText>{helper.is_show.message}</FormHelperText>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="default" variant="contained"
                        onClick={onClose}
                        disabled={loading}
                    >
                        <CancelIcon className={classes.leftIcon} />
                        Cancel
                    </Button>
                    <Button
                        color="primary" variant="contained" style={{ marginRight: 10 }}
                        onClick={handleFormSave}
                        disabled={loading}
                    >
                        {loading
                            ? <CircularProgress size={24} className={classes.leftIcon} />
                            : <CheckIcon className={classes.leftIcon}/>
                        }
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={formConfirmShow} onClose={handleFormConfirmNo}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        Are your sure you want save this data?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFormConfirmNo} variant="contained" color="default">
                        No
                    </Button>
                    <Button onClick={handleFormConfirmYes} variant="contained" color="primary" autoFocus>
                        YES
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}