import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles, } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import Checkbox from '@material-ui/core/Checkbox'

import CellBody from './CellBody'
import TablePaginationActions from './TablePaginationActions'
import TableHeader from './TableHeader'
import TableToolbar from './TableToolbar'
import AddEditForm from './AddEditForm'
import DialogConfirm from '../DialogConfirm'
import Toaster from '../Toaster'

import { doFetch } from '../../utils/Fetch'
import AuthService from '../../auth/AuthService'

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
         return -1;
    }
    if (b[orderBy] > a[orderBy]) {
         return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const useStylesTable = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        borderTop: '2px solid #ffb763',
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}));

export default ({ columns, columnKeys, url, ...rest }) => {
    const Auth = new AuthService()
    const columnsForm = [
        { label: 'Product Name', field: 'product_name', },
        { label: 'Abbr', field: 'product_abbr', },
        { label: 'Desc', field: 'product_desc', },
        { label: 'Feature', field: 'product_feature', type: 'editor' },
        { label: 'Reason', field: 'product_reason', type: 'editor' },
        { label: 'Is Active', field: 'is_active', type: 'select', options: [
            {
                label: 'Y',
                value: 'Y'
            },
            {
                label: 'N',
                value: 'N'
            }
        ]},
        { label: 'Sequence', field: 'seq', type: 'select', options: [
            {
                label: '0',
                value: '0'
            },
            {
                label: '1',
                value: '1'
            }
        ]},
        { label: 'Url Youtube', field: 'url_youtube' },
        { label: 'Logo', field: 'product_logo', type: 'image', 
            options: {
                height: 150,
                width: 150,
            } 
        },
    ]
    const classes = useStylesTable();
    const [loading, setLoading] = useState(false)

    // paging
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [total, setTotal] = useState(0)  

    const [data, setData] = useState([])        
    const [selected, setSelected] = React.useState([]);
    const [oprData, setOprData] = useState({})

    // order
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');

    const [openConfirm, setOpenConfirm] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [opName, setOpName] = useState('')
    
    const [counter, setCounter] = useState(0)

    const [toastState, setToastState] = useState({ open: false, msg: '', error: false})    

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, total - page * rowsPerPage);

    useEffect(() => {
        setLoading(true)
        setData([])
        setSelected([])
        
        const query = async () => {
            if(url.indexOf('?') > -1) {
                setPage(0)
            }

            let tmpUrl = url + (url.indexOf('?') === -1 ? '?' : '&') + `per_page=${rowsPerPage}&page=${page}`
            const result = await fetch(tmpUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Auth.getToken()}`
                }
            })           
            .then(resp => resp.json())
            .catch(err => ({ status: false }))

            if(result.status) {
                setData(result.data)
                setTotal(result.total)                    
            } 
            setLoading(false)
        }
        query()

        // eslint-disable-next-line
    },  [url, page, rowsPerPage, counter])

    const isSelected = tbf => !!selected.find(f => {
        let found = true;
        for(let i=0; i < columnKeys.length; i++) {
            let k = columnKeys[i];
            if(f[k] !== tbf[k]) {
                found = false;
            } 
        }
        return found
    });

    const findIndex = row => {        
        let idx = selected.findIndex(f => {
            let found = true;
            for(let i=0; i < columnKeys.length; i++) {
                let k = columnKeys[i];
                if(f[k] !== row[k]) {
                    found = false
                } 
            }
            return found
        })        
        return idx 
    }

    function handleChangePage(event, newPage) {
        setSelected([])
        setPage(newPage);        
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = data.reduce((acc, curr) => {
                let abc = {}
                for(let i=0; i < columnKeys.length; i++) {
                    let k = columnKeys[i]
                    abc = { ...abc, [k] : curr[k] }
                }  
                return [ ...acc, abc ]
            }, []);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleClickRow(event, row) {
        const selectedIndex = findIndex(row)
        
        let newSelected = [];
    
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    }

    // add
    function handleClickAdd() {
        setOpName("add")
        setIsOpen(true)
    }

    // edit
    function handleClickEdit() {
        setOpName("edit")
        setOprData(selected[0])
        setIsOpen(true)
    }

    // add edit save
    async function handleDialogSave() {        
        let method = opName === 'add' ? "POST" : "PUT"        
        return doFetch(url, JSON.stringify(oprData), method)
            .then(({ message, status }) => {
                if(status) {
                    setCounter(prevCounter => prevCounter + 1)
                    setOprData({})         
                    if(opName === 'edit') {
                        setSelected([])
                    }
                    setIsOpen(false)
                }
                setToastState({ open: true, msg: message, error: !status})
                return status
            })
    }

    // add edit close / cancel
    function handleDialogClose() {
        setOprData({})
        setSelected([])
        setIsOpen(false)
    }

    // delete
    function handleClickDelete() {
        setOpenConfirm(true)
    }

    function handleConfirmNo() {
        setSelected([])
        setOpenConfirm(false)
    }

    function handleConfirmYes() {
        let ids = selected.map(one => {
            let tmp = {};
            for(let i=0; i < columnKeys.length; i++) {
              let attr = columnKeys[i]
              tmp = { ...tmp, [attr]: one[attr] }
            }
            return tmp
        })
        doFetch(url, JSON.stringify(ids), "DELETE")
        .then(resp => {
            let { status, message, statusCd } = resp
            if(status) {
                setCounter(prevCounter => prevCounter + 1)
                setPage(0)
                setOpenConfirm(false)
            } else {
                message = statusCd ? statusCd + " - " + message : message                
            }
            setToastState({ open: true, msg: message, error: !status})
        })
    }
    // delete

    const handleChangeOprData = name => event => {
        if(!event.target) {
            setOprData({ ...oprData, [name]: event });
        } else {
            setOprData({ ...oprData, [name]: event.target.value });
        }
    }

    const handleChangeEditor = name => event => {
        setOprData({ ...oprData, [name]: event.editor.getData() });
    }

    return (
        <Fragment>
            <Paper className={classes.root}>
                <TableToolbar 
                    numSelected={selected.length} 
                    handleAdd={handleClickAdd} 
                    handleEdit={handleClickEdit} 
                    handleDelete={handleClickDelete}
                    />
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} size="small">
                        <TableHeader 
                            headRows={columns}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data.length}
                        />
                        <TableBody>
                            { loading && 
                                <TableRow style={{ height: 48 * emptyRows }}>
                                    <CellBody 
                                        colSpan={columns.length} 
                                        align="center" 
                                        data={<CircularProgress size={60} />}
                                        />
                                </TableRow>
                            }
                            { stableSort(data, getSorting(order, orderBy))
                                .map((row, index) => {
                                    const id = page * rowsPerPage + index
                                    const isItemSelected = isSelected(row)                                
                                    const labelId = `enhanced-table-checkbox-${id}`
                                    
                                    return (
                                        <TableRow
                                            hover
                                            onClick={event => handleClickRow(event, row)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={id}
                                            selected={isItemSelected}
                                            >
                                            {   columns.map((c, index) => {
                                                    return index === 0 ? 
                                                        <CellBody 
                                                            component="th" 
                                                            scope="row" 
                                                            key={index}
                                                            type={c.type} 
                                                            options={c.options} 
                                                            data={row[c.field]}
                                                            label={c.label}
                                                            >                                                            
                                                        </CellBody>
                                                        :
                                                        <CellBody 
                                                            key={index} 
                                                            type={c.type} 
                                                            options={c.options} 
                                                            data={row[c.field]}
                                                            label={c.label}
                                                            >                                                            
                                                        </CellBody>
                                                })
                                            }
                                            <CellBody 
                                                padding="checkbox" 
                                                data={<Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                }
                                                >
                                            </CellBody>
                                        </TableRow>
                                    )
                                }) 
                            }
                            {!loading && emptyRows > 0 && (
                                <TableRow style={{ height: 48 * emptyRows }}>
                                    <CellBody 
                                        colSpan={columns.length + 1} 
                                        align="center"
                                        data={ emptyRows === rowsPerPage && 
                                            "No Data Found"
                                        }>
                                    </CellBody>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    colSpan={columns.length + 1}
                                    count={total}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'Rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
            </Paper>
            <AddEditForm 
                title={opName}
                columns={columnsForm} 
                columnKeys={columnKeys} 
                data={oprData}
                handleData={handleChangeOprData}
                handleEditor={handleChangeEditor}
                isOpen={isOpen} 
                handleSave={handleDialogSave}
                handleClose={handleDialogClose} />
            <DialogConfirm
                message="Delete selected data?"
                open={openConfirm}
                handleNo={handleConfirmNo}
                handleYes={handleConfirmYes}
            />
            <Toaster onOpen={ toastState.open } 
                onClose={ () => setToastState({ open: false, msg: '', error: false })  } 
                message={ toastState.msg }
                variant={ toastState.error ? "error" : "success" }
            /> 
        </Fragment>
    );
}