import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Select from 'react-select';
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import NoSsr from '@material-ui/core/NoSsr'
import MenuItem from '@material-ui/core/MenuItem'
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles'

import { useFetch } from '../utils/Fetch'
import { API_URL } from '../utils/Constant'

import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: 250,
        marginTop: 8,
        marginBottom: 4
    },
    input: {
      display: 'flex',
      padding: 0,
      height: 'auto',
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
    },
    singleValue: {
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      bottom: 6,
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
}));

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
            >
            {props.children}
        </Typography>
    );
}
  
NoOptionsMessage.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
    const {
      children,
      innerProps,
      innerRef,
      selectProps: { classes, TextFieldProps },
    } = props;
  
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? "bold" : "normal",            
            }}
            {...props.innerProps}
            >
            {props.children}            
        </MenuItem>
    );
}
  
Option.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
};

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
            >
            {props.children}
        </Typography>
    );
}
  
Placeholder.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}
  
SingleValue.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}
  
ValueContainer.propTypes = {
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
                })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}
  
MultiValue.propTypes = {
    children: PropTypes.node,
    isFocused: PropTypes.bool,
    removeProps: PropTypes.object.isRequired,
    selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}
  
Menu.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object,
};

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
}

/* suggestion is array of object { value , label } */
export default ({ label, placeholder, inputId, options, value, handler, ismulti, url, variant, ...rest }) => {
    const classes = useStyles()
    const theme = useTheme();
    const isFromApi = url !== undefined && url !== "";
    const { data: fetched , isLoading } = isFromApi && useFetch(API_URL.COMBO + url)

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),        
    };

    // desperate way to prefill combo box from other screen
    if(typeof(value) === "object") {
        if(value.label === '') {
            if(fetched.data.length > 0) {
                let tmp = fetched.data.filter(f => f.value === value.value)
                if(tmp.length > 0) {
                    value.label = tmp[0].label
                }
            }
        }
    }

    return (
        <NoSsr>
            <Select
                classes={classes}
                styles={selectStyles}
                inputId={inputId}
                TextFieldProps={{
                    label,
                    placeholder: isFromApi && isLoading ? "loading data..." : placeholder,
                    InputLabelProps: {
                        htmlFor: inputId,
                        shrink: true,
                    },
                    variant
                }}
                options={ isFromApi ? (isLoading && fetched.status ? [] : fetched.data) : options }
                components={components}
                value={value}
                onChange={handler}
                {...rest}
            />
        </NoSsr>
    )
}