import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import CardMedia from '@material-ui/core/CardMedia'
import { makeStyles } from '@material-ui/core/styles'
import Logo from '../../assets/images/logoOnly.png'
import Toaster from '../core/Toaster'
import AuthService from './AuthService'
import { Copyright } from '../layout'


const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }
}));

export default function SignIn({ history }) {
  const classes = useStyles();
  const [formValue, setFormValue] = useState({ username: '', password: ''})
  const [postResult, setPostResult] = useState({ message: '', status: true})  

  const Auth = new AuthService()
  
  if(Auth.loggedIn()) {
    history.replace('/')
  }

  const handleChange = name => event => {
    setFormValue({ ...formValue, [name]: event.target.value });
  };

  function handleOnClickSignIn() {
    Auth.login(formValue.username, formValue.password)
      .then(({ message, status }) => {
        if(status) {
          history.replace("/")
        } else {
          setPostResult({ message, status })
        }
      })
  }

  function handleKeyUp(e) {
    if(e.key === 'Enter') {
      handleOnClickSignIn()
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <CardMedia image={Logo} style={{ height: 50, width: 50, marginBottom: 10 }} />
        <Typography component="h1" variant="h5" gutterBottom>
          BSP Site Admin
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          onChange={handleChange('username')}
          onKeyUp={handleKeyUp}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={handleChange('password')}
          onKeyUp={handleKeyUp}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleOnClickSignIn}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            &nbsp;
          </Grid>
          <Grid item>
            <Link href="/forgot-password" variant="body2">
              Forgot password?
            </Link>
          </Grid>
        </Grid>        
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
      <Toaster onOpen={ !postResult.status } 
        onClose={ () => setPostResult({ status: true, message: '' })  } 
        message={ postResult.message }
        variant="error"
      />      
    </Container>
  );
}