import React from 'react'
import StyledTableCell from './TableCell'
import { STATIC_URL } from '../../utils/Constant'

const CellBody = ({ type, options, label, data, operationType, ...rest }) => {
    let rand = ''
    if(operationType === 'edit') {
        rand = Math.random()
    }

    let styleImg
    if(type === 'image') {
        styleImg = {}
        if(options.width !== '100%') {
            styleImg.width = options.width
        }
        styleImg.height = options.height
    }

    return (
        <StyledTableCell {...rest}>
            { type === 'image' ?
                <div style={{ textAlign: 'center' }}>
                    <img src={`${STATIC_URL}${data}?r=${rand}`}
                        alt={label} 
                        style={styleImg}
                    />
                </div>
                :
                type === 'select' ? 
                <div>
                    { options.filter(f => f.value === data).length > 0 ? options.filter(f => f.value === data)[0].label : '' }
                </div>
                : 
                data
            }
        </StyledTableCell>
    )
}

export default CellBody