import React, { useState, useEffect, Fragment } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import TitleAndBreadcrumbs from '../core/TitleAndBreadcrumbs'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from '../core/table/TablePaginationActions'
import TableToolbar from '../core/table/TableToolbar'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import ExpansionPanel from '../core/ExpansionPanel'

import CircularProgress from '@material-ui/core/CircularProgress'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import SearchIcon from '@material-ui/icons/Search'
import CancelIcon from '@material-ui/icons/Cancel'

import FormDialog from './form'
import DialogConfirm from '../core/DialogConfirm'
import Toaster from '../core/Toaster'

import api from './api'

const TableCellStyled = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
        fontSize: '1.2em',
        fontWeight: 'bold',
        borderRight: '1px solid rgba(244, 244, 244, 1)',
        textAlign: 'center',
        textTransform: 'capitalize',
    },
    body: {
        borderRight: '1px solid rgba(200, 200, 200, 1)',
        borderBottom: '1px solid rgba(200, 200, 200, 1)',
    },
}))(TableCell)

const useStylesTable = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        borderTop: '2px solid #ffb763',
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}))

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        marginRight: theme.spacing(5),
        minWidth: 200,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}))

export default () => {
    const classesTable = useStylesTable()
    const classes = useStyles()

    const [loading, setLoading] = useState(false)

    // filter
    const [filter, setFilter] = useState({ name: '' })

    // table
    const tableHeader = ['name', 'review', 'title', 'show']
    const [tableFilter, setTableFilter] = useState({})
    const [tableSortBy, setTableSortBy] = useState(null)
    const [tableSortDir, setTableSortDir] = useState({ name: 'asc', review: 'asc', title: 'asc', show: 'asc' })
    const [tableData, setTableData] = useState([])
    const [tableSelected, setTableSelected] = useState([])
    const [tablePage, setTablePage] = useState(0)
    const [tableSize, setTableSize] = useState(5)
    const [tableCount, setTableCount] = useState(0)

    // form
    const [formDialogOpen, setFormDialogOpen] = useState(false)
    const [formDialogMode, setFormDialogMode] = useState('')
    const [formDialogData, setFormDialogData] = useState({})

    // delete
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    // toast
    const [toastState, setToastState] = useState({ open: false, msg: '', error: false })

    // FILTER HANDLER
    // change
    const handleFilterChange = name => event => {
        setFilter({ ...filter, [name]: event.target.value });
    }

    // apply
    const handleFilterApply = () => {
        handleTableSearch(filter)
    }

    // reset
    const handleFilterReset = () => {
        setFilter({ name: '' })
        handleTableSearch({ name: '' })
    }

    // TABLE HANDLER
    // sort
    const handleTableSort = header => event => {
        if (tableSortBy === header) {
            if (tableSortDir[header] === 'asc') {
                setTableSortBy(header)
                setTableSortDir(state => ({ ...state, [header]: 'desc' }))
            } else {
                setTableSortBy(null)
                setTableSortDir(state => ({ ...state, [header]: 'asc' }))
            }
        } else {
            setTableSortBy(header)
            if (tableSortBy) {
                setTableSortDir(state => ({ ...state, [tableSortBy]: 'asc' }))
            }
        }
    }

    // select
    const handleTableSelect = row => event => {
        let newSelected = [row]
        if (tableSelected.length > 0) {
            const index = tableSelected.findIndex(item => item.id === row.id)
            if (index === 0) {
                newSelected = tableSelected.slice(1)
            } else if (index === tableSelected.length) {
                newSelected = tableSelected.slice(index, tableSelected.length)
            } else if (index > 0) {
                newSelected = [].concat(
                    tableSelected.slice(0, index),
                    tableSelected.slice(index + 1),
                )
            } else {
                newSelected = [].concat(tableSelected, row)
            }
        }
        setTableSelected(newSelected)
    }

    // select all
    const handleTableSelectAll = event => {
        if (event.target.checked) {
            setTableSelected(tableData)
        } else {
            setTableSelected([])
        }
    }

    // page
    const handleTablePaging = (event, page) => {
        setTablePage(page)
    }

    // size
    const handleTableSizing = event => {
        setTableSize(event.target.value)
    }

    // search
    const handleTableSearch = async (filter) => {
        setLoading(true)
        setTableSelected([])
        let params = {}
        if (filter) {
            setTableFilter(filter)
            setTableSortBy(null)
            setTablePage(0)
            params.page = 0
            params.per_page = tableSize
            params = { ...filter, ...params }
        } else {
            params.page = tablePage
            params.per_page = tableSize
            params.sort_by = tableSortBy
            params.sort_dir = tableSortBy ? tableSortDir[tableSortBy] : null
            params = { ...tableFilter, ...params }
        }
        
        const response = await api.search(params)
        if (response.status) {
            setTableData(response.data)
            setTableCount(response.total)
        } else {
            handleToast(response)
        }
        setLoading(false)
    }

    // FORM HANDLER
    // add
    const handleDialogAdd = () => {
        setFormDialogMode("add")
        setFormDialogOpen(true)
    }

    // edit
    const handleDialogEdit = () => {
        setFormDialogMode('edit')
        setFormDialogData(tableSelected[0])
        setFormDialogOpen(true)
    }

    // close
    const handleFormDialogClose = () => {
        setFormDialogData({})
        setFormDialogOpen(false)
    }

    // save
    const handleSave = response => {
        handleTableSearch()
    }

    // DELETE HANDLER
    // delete
    function handleDialogDelete() {
        setDeleteDialogOpen(true)
    }

    // delete confirm no
    function handleDialogDeleteNo() {
        setDeleteDialogOpen(false)
    }

    // dlete confirm yes
    const handleDialogDeleteYes = async () => {
        setLoading(true)
        setDeleteDialogOpen(false)
        const response = await api.delete(tableSelected)
        if (response.status) {
            await handleTableSearch()
            handleToast(response)
        } else {
            handleToast(response)
        }
        setLoading(false)
    }

    // TOAST HANDLER
    const handleToast = toast => {
        setToastState({ open: true, msg: toast.message, error: !toast.status })
    }

    // TABLE SORT & PAGE LISTENER
    useEffect(() => {
        handleTableSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tablePage, tableSize, tableSortBy, tableSortDir])

    return (
        <Fragment>
            <TitleAndBreadcrumbs title="Reviews" breads={[ "Reviews" ]} />
            <ExpansionPanel title="Filter" defaultExpanded>
                <Grid container className={classes.filterContent} justify="flex-start" alignItems="center">
                    <Grid item>
                        <div className={classes.formControl}>
                            <TextField
                                autoFocus
                                label="Name"
                                value={filter.name}
                                onChange={handleFilterChange('name')}
                                onKeyDown={e => {if (e.keyCode === 13) { handleFilterApply() }}}
                                InputLabelProps={{ shrink: true }}
                                disabled={loading}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify="flex-end" spacing={2}>
                            <Grid item>
                                <Button
                                    variant="contained" color="default" size="medium"
                                    onClick={handleFilterReset}
                                    disabled={loading}
                                >
                                    <CancelIcon className={classes.leftIcon} /> Reset
                                </Button> 
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained" color="primary" size="medium"
                                    onClick={handleFilterApply}
                                    disabled={loading}
                                >
                                    <SearchIcon className={classes.leftIcon} /> Apply
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ExpansionPanel>
            <Paper className={classesTable.root}>
                <TableToolbar
                    numSelected={tableSelected.length}
                    handleAdd={handleDialogAdd}
                    handleEdit={handleDialogEdit}
                    handleDelete={handleDialogDelete}
                />
                <div className={classesTable.tableWrapper}>
                    <Table className={classesTable.table} size="small">
                        <TableHead>
                            <TableRow>
                                {tableHeader.map((header, i) => (
                                <TableCellStyled key={`table-header-${i}`} sortDirection={tableSortBy === header ? tableSortDir[header] : false }>
                                    <TableSortLabel
                                        active={tableSortBy === header}
                                        direction={tableSortDir[header]}
                                        onClick={handleTableSort(header)}
                                        disabled={loading}
                                    >
                                        {header}
                                    </TableSortLabel>
                                </TableCellStyled>
                                ))}
                                <TableCellStyled padding="checkbox">
                                    <Checkbox
                                        style={{ color: 'white' }}
                                        indeterminate={tableSelected.length > 0 && tableSelected.length < tableData.length}
                                        checked={tableData.length > 0 && tableSelected.length === tableData.length}
                                        onChange={handleTableSelectAll}
                                        disabled={loading}
                                    />
                                </TableCellStyled>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading &&
                                <TableRow style={{ height: 48 * tableSize }}>
                                    <TableCellStyled colSpan={5} align="center">
                                        <CircularProgress size={60} />
                                    </TableCellStyled>
                                </TableRow>
                            }
                            {(!loading && tableData.length > 0) && tableData.map((row, i) => {
                                const isSelected = tableSelected.findIndex(item => row.id === item.id) > -1
                                return (
                                    <TableRow
                                        hover
                                        key={`table-row-${row.id}`}
                                        onClick={handleTableSelect(row)}
                                        selected={isSelected}
                                    >
                                        <TableCellStyled>{row.name}</TableCellStyled>
                                        <TableCellStyled>{row.review}</TableCellStyled>
                                        <TableCellStyled>{row.title}</TableCellStyled>
                                        <TableCellStyled>{row.is_show === 'Y' ? 'Yes' : 'No'}</TableCellStyled>
                                        <TableCellStyled padding="checkbox">
                                            <Checkbox checked={isSelected} />
                                        </TableCellStyled>
                                    </TableRow>
                                )
                            })}
                            {(!loading && tableData.length < tableSize) &&
                                <TableRow style={{ height: 48 * tableSize }}>
                                    <TableCellStyled colSpan={5} align="center">
                                        {tableData.length === 0 && 'No Data Found' }
                                    </TableCellStyled>
                                </TableRow>
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    colSpan={5}
                                    count={tableCount}
                                    rowsPerPage={tableSize}
                                    page={tablePage}
                                    onChangePage={handleTablePaging}
                                    onChangeRowsPerPage={handleTableSizing}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
            </Paper>
            <FormDialog
                mode={formDialogMode}
                open={formDialogOpen}
                data={formDialogData}
                onToast={handleToast}
                onClose={handleFormDialogClose}
                onSave={handleSave}
            />
            <DialogConfirm
                message="Delete selected data?"
                open={deleteDialogOpen}
                handleNo={handleDialogDeleteNo}
                handleYes={handleDialogDeleteYes}
            />
            <Toaster onOpen={toastState.open}
                onClose={() => setToastState({ open: false, msg: '', error: false })}
                message={toastState.msg}
                variant={toastState.error ? "error" : "success"}
            />
        </Fragment>
    )
}