import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import { Toolbar, Typography, Button } from '@material-ui/core'

import AddIcon from '@material-ui/icons/AddCircle'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import DirectionsIcon from '@material-ui/icons/Directions';
// import FilterListIcon from '@material-ui/icons/FilterList'

const useToolbarStyles = makeStyles(theme => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(0, 0, 0, 1),
    },
}));

// const LightTooltip = withStyles(theme => ({
//     tooltip: {
//       backgroundColor: theme.palette.common.white,
//       color: 'rgba(0, 0, 0, 0.87)',
//       boxShadow: theme.shadows[1],
//       fontSize: 11,
//     },
// }))(Tooltip);

const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const { numSelected, handleAdd, handleEdit, handleDelete, handleDetail } = props;
  
    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
            >
            <div className={classes.title}>
                {numSelected > 0 && (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                )}
            </div>
            <div className={classes.spacer} />
            {
                numSelected === 1 && handleDetail &&
                <div className={classes.actions}>                
                    <Button variant="contained" className={classes.button} color="secondary" onClick={handleDetail}>
                        <DirectionsIcon className={classes.leftIcon} />
                        Detail
                    </Button>
                </div>
            }
            {
                numSelected === 1 && 
                <div className={classes.actions}>                
                    <Button variant="contained" className={classes.button} color="primary" onClick={handleEdit}>
                        <EditIcon className={classes.leftIcon} />
                        Edit
                    </Button>
                </div>        
            }
            {
                numSelected > 0 ? (
                <div className={classes.actions}>                
                    <Button variant="contained" className={classes.button} onClick={handleDelete}>
                        <DeleteIcon className={classes.leftIcon} />
                        Delete
                    </Button>
                </div>                        
            ) : (
                <div className={classes.actions}>                
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleAdd}>
                        <AddIcon className={classes.leftIcon} />
                        Add
                    </Button>                
                </div>
            )}
        </Toolbar>
    );
};
  
EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default EnhancedTableToolbar