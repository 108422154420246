import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles'

import '../assets/css/animation.css'
import NotFound from './error'
import { Layout } from './layout'
import { Login, AuthService } from './auth'
import Dashboard from './dashboard'
import PagesMeta from './pageMeta'
import Client from './client'
import Product from './product'
import Reviews from './reviews'
import Team from './team'
import ContactUs from './contactUs'
import Testimony from './testimony'
import Blog from './blog'
import { User, System } from './master'
import Career from './career'
import { Gallery, Instructor, PortfolioClient, PortfolioProject } from './content'

const { innerWidth: width } = window;

const styles = createMuiTheme({
    palette: {
        common: {
            black: "#000",
            white: "#fff"
        },
        background: {
            paper: "#fff",
            default: "#fafafa"
        },    
        secondary: {
            light: "#5e5761",
            main: "#342e37",
            dark: "#0e0411",
            contrastText: "#fff"
        },
        primary: {
            light: "#ffb763",
            main: "#f58634",
            dark: "#bc5800",
            contrastText: "#fff"
        },
        error: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: "#fff"
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)"
        }
    },
    typography: {
        fontSize: width > 1366 ? 14 : 12
    },
    overrides: {
        MuiListItemIcon: {
            root: {
              minWidth: 40,
            }
        },
        MuiButton: {
            root: {
              borderRadius: 25
            }
        },
        MuiFormLabel: {
            root: {
              color: 'black',
              fontSize: 16
            }
        }
    }
})

const OpenRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <ThemeProvider theme={styles} >
                <Component {...matchProps} />
            </ThemeProvider>
        )} />
    )
}

const PrivateRoute = ({component: Component, path, ...rest}) => {
    const Auth = new AuthService()

    return (
        <Route {...rest} render={matchProps => 
            Auth.loggedIn() ? (
                <ThemeProvider theme={styles}>
                  <Layout>
                    <Component user={Auth.getProfile()} {...matchProps} /> 
                  </Layout>          
                </ThemeProvider>
            ) : ( 
                <Redirect to={{ pathname: "/login", state: { from: matchProps.location }}} /> 
            )
        } 
        />    
    )
}

export default () => {
    return <Router>
        <Helmet 
            titleTemplate="%s - Bee Solution Partners Admin" 
            defaultTitle="Bee Solution Partners Admin">
        </Helmet>
        <Switch>
            <OpenRoute 
                exact 
                path="/" 
                component={ (matchProps) => 
                    <Redirect to={{ pathname: "/dashboard", state: { from: matchProps.location } }} /> 
                } 
            />
            <OpenRoute exact path="/login" component={ Login } />
            <Route render={({ location }) => (
                <Fragment>                    
                    <TransitionGroup>
                        <CSSTransition
                            key={location.key}
                            classNames="fade"
                            timeout={{
                                appear: 0,
                                enter: 300,
                                exit: 300
                            }}
                            >
                            <Switch location={location}>
                                <PrivateRoute exact path="/dashboard" component={ Dashboard } />
                                <PrivateRoute exact path="/pagesMeta" component={ PagesMeta } />
                                <PrivateRoute exact path="/system" component={ System } />
                                <PrivateRoute exact path="/client" component={ Client } />
                                <PrivateRoute exact path="/product" component={ Product } />
                                <PrivateRoute exact path="/user" component={ User } />
                                <PrivateRoute exact path="/reviews" component={ Reviews } />
                                <PrivateRoute exact path="/team" component={ Team } />
                                <PrivateRoute exact path="/contactUs" component={ ContactUs } />
                                <PrivateRoute exact path="/testimony" component={ Testimony } />
                                <PrivateRoute exact path="/blog" component= { Blog } />
                                <PrivateRoute exact path="/career" component= { Career } />
                                <PrivateRoute exact path="/gallery" component= { Gallery } />
                                <PrivateRoute exact path="/instructor" component= { Instructor } />
                                <PrivateRoute exact path="/portfolio/client" component= { PortfolioClient } />
                                <PrivateRoute exact path="/portfolio/project" component= { PortfolioProject } />
                                <OpenRoute component={ NotFound } />
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                </Fragment>
            )}
            />
        </Switch>
    </Router>  
}
